// src/pages/About/index.jsx
import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    Flex,
    Heading,
    Text,
    Image,
    Stack,
    SimpleGrid,
    Button,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import Header from '../../components/header';
import { Footer } from '../../components/footer';
import { colors } from '../../styles/colors';
import OurValues from '../../components/ourValues';
import Api, { exceptionNotificationAPI } from '../../services/api';

const MotionBox = motion(Box);

const About = () => {
    const [categories, setCategories] = useState([]);
    const dataFetchedRef = useRef(false);

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;

        const fetchCategories = async () => {
            try {
                const response = await Api.get('/planos-categorias');
                setCategories(response.data);
            } catch (error) {
                exceptionNotificationAPI(error);
            }
        };

        fetchCategories();
    }, []);

    return (
        <>
            <Header />

            {/* Hero Section */}
            <MotionBox
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                position="relative"
                bgImage={require('../../assets/images/hero-about.jpg')}
                bgSize="cover"
                bgPosition="center"
                minH={{ base: '70vh', md: '80vh' }}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                {/* Overlay Escuro */}
                <Box
                    position="absolute"
                    top={0}
                    left={0}
                    width="100%"
                    height="100%"
                    bg="rgba(0, 0, 0, 0.6)"
                />

                <Box textAlign="center" px={4} zIndex={1}>
                    <Heading
                        fontSize={{ base: '3xl', md: '5xl' }}
                        color={colors.white}
                        mb={4}
                    >
                        Sobre a <Text as="span" color={colors.secondary}>FLW</Text>
                    </Heading>
                    <Text fontSize={{ base: 'md', md: 'xl' }} color={colors.white}>
                        Transformando a forma como você se conecta com o mundo.
                    </Text>
                </Box>
            </MotionBox>

            {/* Nossa História */}
            <Box py={16} px={4} bg={colors.white}>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} alignItems="center">
                    <Image
                        src={require('../../assets/images/our-story.jpg')}
                        alt="Nossa História"
                        borderRadius="md"
                        shadow="md"
                    />
                    <Box>
                        <Heading mb={6} color={colors.black}>
                            Nossa História
                        </Heading>
                        <Text fontSize="lg" color={colors.gray[600]} mb={4}>
                            Fundada em 29 de maio de 2024, a FLW nasceu com o propósito de revolucionar a telefonia móvel no Brasil. Como uma operadora móvel virtual (MVNO), acreditamos que a conexão vai além de sinais e ondas eletromagnéticas; ela está nas relações humanas, nas histórias compartilhadas e nas oportunidades criadas.
                        </Text>
                        <Text fontSize="lg" color={colors.gray[600]}>
                            Nossa missão é proporcionar aos nossos clientes uma experiência personalizada e flexível, oferecendo planos que se adaptam às suas necessidades e estilo de vida. Com foco na inovação e no atendimento de excelência, estamos comprometidos em ser a operadora que coloca você em primeiro lugar.
                        </Text>
                    </Box>
                </SimpleGrid>
            </Box>

            {/* Nossos Valores */}
            <OurValues />

            {/* Nossos Planos Especiais */}
            <Box py={16} px={4} bg={colors.white}>
                <Heading textAlign="center" mb={12} color={colors.black}>
                    Nossos Planos Especiais
                </Heading>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} alignItems="center">
                    <Box>
                        <Text fontSize="lg" color={colors.gray[600]} mb={4}>
                            Sabemos que cada pessoa é única e tem necessidades diferentes. Por isso, criamos planos especiais para atender a diversos perfis:
                        </Text>
                        <Stack spacing={6}>
                            {categories.map((category) => (
                                <Box key={category.id}>
                                    <Text fontSize="xl" fontWeight="bold" color={colors.primary}>
                                        {category.nome}
                                    </Text>
                                    <Text color={colors.gray[600]}>
                                        {category.detalhes}
                                    </Text>
                                </Box>
                            ))}
                        </Stack>
                        <Button
                            mt={8}
                            colorScheme="brand"
                            onClick={() => {
                                // Navegação para a página de planos
                            }}
                        >
                            Conheça nossos planos
                        </Button>
                    </Box>
                    <Image
                        src={require('../../assets/images/special-plans.jpg')}
                        alt="Planos Especiais"
                        borderRadius="md"
                        shadow="md"
                    />
                </SimpleGrid>
            </Box>

            {/* Nossa Equipe */}
            <Box py={16} px={4} bg={colors.gray[50]}>
                <Heading textAlign="center" mb={12} color={colors.black}>
                    Nossa Equipe
                </Heading>
                <Text fontSize="lg" color={colors.gray[600]} textAlign="center" mb={12} maxW="800px" mx="auto">
                    Formada por profissionais experientes e apaixonados pelo que fazem, nossa equipe está dedicada a oferecer o melhor serviço para você.
                </Text>
                {/* Você pode adicionar fotos da equipe aqui */}
            </Box>

            {/* CTA Final */}
            <Box py={16} px={4} bg={colors.primary}>
                <Flex direction="column" alignItems="center">
                    <Heading
                        textAlign="center"
                        mb={6}
                        color={colors.white}
                        fontSize={{ base: '2xl', md: '4xl' }}
                    >
                        Junte-se a nós e faça parte dessa mudança!
                    </Heading>
                    <Button size="lg" colorScheme="secondary" color={colors.black}>
                        Seja FLW
                    </Button>
                </Flex>
            </Box>

            <Footer />
        </>
    );
};

export default About;