// src/components/CardPlan.jsx
import React from 'react';
import { Box, Text, Button, List, ListItem, ListIcon } from '@chakra-ui/react';
import { colors } from '../styles/colors';
import { CheckCircleIcon } from '@chakra-ui/icons';

const CardPlan = ({ categoryName, plan, color, onSubscribe }) => {
    return (
        <Box
            textAlign="center"
            px={6}
            py={8}
            bg={color}
            borderRadius="md"
            shadow="lg"
            color={colors.white}
        >
            <Text fontSize="2xl" fontWeight="bold" mb={2}>
                {plan.plano}
            </Text>
            <Text fontSize="xl" fontWeight="bold" mb={2}>
                {categoryName}
            </Text>
            <Text fontSize="4xl" fontWeight="bold" mb={4}>
                R$ {plan.valor.toFixed(2)}/mês
            </Text>
            <Text fontSize="md" mb={4}>
                +5GB na portabilidade
            </Text>
            <List spacing={3} textAlign="left" mb={6}>
                <ListItem>
                    <ListIcon as={CheckCircleIcon} color={colors.secondary} />
                    {plan.internet_padrao} de internet
                </ListItem>
                <ListItem>
                    <ListIcon as={CheckCircleIcon} color={colors.secondary} />
                    {plan.ligacoes}
                </ListItem>
                <ListItem>
                    <ListIcon as={CheckCircleIcon} color={colors.secondary} />
                    Internet que acumula
                </ListItem>
                <ListItem>
                    <ListIcon as={CheckCircleIcon} color={colors.secondary} />
                    WhatsApp ilimitado
                </ListItem>
                {/* Adicione outros benefícios conforme necessário */}
            </List>
            <Button colorScheme="secondary" color={colors.black} onClick={onSubscribe}>
                Assine agora
            </Button>
        </Box>
    );
};

export default CardPlan;
