import { useEffect } from 'react';
import { createBrowserRouter, RouterProvider, useLocation } from 'react-router-dom';
import Home from '../pages/Home';
import About from '../pages/About';
import Plans from '../pages/Plans';
import FAQ from '../pages/FAQ';
import RouteWrapper from './route';

const RoutesList = () => {
    const ScrollToTop = ({ children }) => {
        const location = useLocation();

        useEffect(() => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }, [location.pathname]);

        return children;
    };

    const router = createBrowserRouter([
        { path: "/", element: <><RouteWrapper element={Home} /><ScrollToTop /></> },
        { path: "/about", element: <><RouteWrapper element={About} /><ScrollToTop /></> },
        { path: "/plans", element: <><RouteWrapper element={Plans} /><ScrollToTop /></> },
        { path: "/faq", element: <><RouteWrapper element={FAQ} /><ScrollToTop /></> },
    ]);

    return (
        <RouterProvider router={router} />
    );
};

export default RoutesList;