// src/hooks/auth.jsx
import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import Api, { exceptionNotificationAPI } from '../services/api';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(() => {
        const storedToken = localStorage.getItem('@FlwApp:token');
        if (storedToken) {
            Api.defaults.headers.Authorization = `Bearer ${storedToken}`;
            return storedToken;
        }
        return null;
    });
    const [loading, setLoading] = useState(true);

    // Ref to prevent multiple authentication calls
    const authFetchedRef = useRef(false);

    useEffect(() => {
        if (authFetchedRef.current) return;
        authFetchedRef.current = true;

        const authenticate = async () => {
            try {
                const response = await Api.post('/auth/token', {
                    chave_acesso: 'SgEMYuVyHiRquv2x91J2QRAN4vM8Er6s5sxBFOXFrf1LpVG5NR4EfIYAf0SniWua',
                    chave_acesso_franquia: '',
                });
                const { token } = response.data;
                Api.defaults.headers.Authorization = `Bearer ${token}`;
                localStorage.setItem('@FlwApp:token', token);
                setToken(token);
            } catch (error) {
                exceptionNotificationAPI(error);
            } finally {
                setLoading(false);
            }
        };

        if (!token) {
            authenticate();
        } else {
            setLoading(false);
        }
    }, []); // Empty dependency array ensures useEffect runs only once on mount

    return (
        <AuthContext.Provider value={{ token, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export function useAuth() {
    return useContext(AuthContext);
}