import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { colors } from './colors';

const config = {
    initialColorMode: 'light',
    useSystemColorMode: false,
};

const styles = {
    global: (props) => ({
        '*': {
            margin: 0,
            padding: 0,
            boxSizing: 'border-box',
        },
        body: {
            bg: mode(colors.background, colors.background)(props),
            color: mode(colors.black, colors.white)(props),
        },
    }),
};

const fonts = {
    heading: `'Plus Jakarta Sans', sans-serif`,
    body: `'Plus Jakarta Sans', sans-serif`,
};

const components = {
    Toast: {
        baseStyle: {
            container: {
                bg: colors.gray[800],
                color: colors.white,
                borderRadius: 'md',
                boxShadow: 'lg',
            },
            title: {
                fontWeight: 'bold',
            },
            description: {
                fontSize: 'md',
            },
            status: {
                error: {
                    bg: colors.red,
                    color: colors.white,
                },
                success: {
                    bg: colors.green,
                    color: colors.white,
                },
                info: {
                    bg: colors.blue,
                    color: colors.white,
                },
                warning: {
                    bg: colors.yellow,
                    color: colors.black,
                },
            },
        },
    },
};

const theme = extendTheme({
    config,
    styles,
    colors,
    fonts,
    components,
});

export default theme;
