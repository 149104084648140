import React from 'react';
import {
    Box,
    Text,
    Button,
    List,
    ListItem,
    ListIcon,
    useColorModeValue,
    Link,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';
import { colors } from '../styles/colors';

const MotionBox = motion(Box);

const CardPlanPlan = ({ plan, categoryName }) => {
    // Construir a mensagem com formatação
    const message = `Olá, gostaria de uma nova ativação.\n\nPlano: *${plan.plano}*\nCategoria: *${categoryName}*`;

    // Codificar a mensagem
    const encodedMessage = encodeURIComponent(message);

    // Link do WhatsApp com a mensagem pré-preenchida
    const whatsappNumber = '558008002101'; // Número do WhatsApp
    const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodedMessage}`;

    return (
        <MotionBox
            whileHover={{ y: -10 }}
            transition={{ duration: 0.3 }}
            textAlign="center"
            px={4}
            py={6}
            bg={useColorModeValue(colors.white, colors.gray[800])}
            borderRadius="md"
            shadow="md"
        >
            <Text fontSize="2xl" fontWeight="bold" mb={2} color={colors.black}>
                {plan.plano}
            </Text>
            <Text fontSize="xl" fontWeight="bold" mb={2} color={colors.primary}>
                R$ {plan.valor.toFixed(2)}
            </Text>
            <Text fontSize="md" mb={4} color={colors.gray[600]}>
                +5GB na portabilidade
            </Text>
            <List spacing={2} textAlign="left" mb={4} color={colors.gray[600]}>
                <ListItem>
                    <ListIcon as={CheckCircleIcon} color={colors.secondary} />
                    {plan.ligacoes}
                </ListItem>
                <ListItem>
                    <ListIcon as={CheckCircleIcon} color={colors.secondary} />
                    Internet que acumula
                </ListItem>
                <ListItem>
                    <ListIcon as={CheckCircleIcon} color={colors.secondary} />
                    WhatsApp ilimitado
                </ListItem>
                {/* Adicione outros benefícios conforme necessário */}
            </List>
            <Link href={whatsappLink} isExternal style={{ textDecoration: 'none' }}>
                <Button colorScheme="secondary" color={colors.black}>
                    Assine agora
                </Button>
            </Link>
        </MotionBox>
    );
};

export default CardPlanPlan;