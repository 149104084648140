// src/pages/Home/index.jsx
import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Icon,
  SimpleGrid,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { FaWifi, FaMobileAlt, FaWhatsapp, FaGlobe } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/header';
import { Footer } from '../../components/footer';
import Testimonials from '../../components/testimonials';
import { colors } from '../../styles/colors';
import { Loading } from '../../components/loading';
import CardPlan from '../../components/cardPlan';
import Api, { exceptionNotificationAPI } from '../../services/api';
import { useAuth } from '../../hooks/auth';

// Animações com Framer Motion
const MotionBox = motion(Box);
const MotionImage = motion(Box);

const Home = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  // Import token and loading from useAuth
  const { token, loading } = useAuth();

  // Usando useRef para evitar múltiplas requisições
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (!token || loading) return; // Wait until token is available and authentication is complete

    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    const fetchData = async () => {
      try {
        // Fetch categories
        const categoriesResponse = await Api.get('/planos-categorias');
        const categoriesData = categoriesResponse.data;

        // For each category, fetch plans
        const categoriesWithPlans = await Promise.all(
          categoriesData.map(async (category) => {
            const plansResponse = await Api.get(`/planos/${category.id}`);
            const plansData = plansResponse.data.data;
            return {
              ...category,
              plans: plansData,
            };
          })
        );

        setCategories(categoriesWithPlans);
        setLoadingData(false);
      } catch (error) {
        exceptionNotificationAPI(error);
        setLoadingData(false);
      }
    };

    fetchData();
  }, [token, loading]); // Depend on token and loading

  // Alternar cores entre primary e secondary
  const colorsArray = [colors.primary, colors.secondary];

  return (
    <>
      <Header />

      {/* Hero Section */}
      <MotionBox
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        position="relative"
        bgImage={require('../../assets/images/hero-background.jpg')}
        bgSize="cover"
        bgPosition="center"
        minH="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {/* Overlay Escuro */}
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          bg="rgba(0, 0, 0, 0.6)"
        />
        <Box textAlign="center" px={4} zIndex={1}>
          <Heading
            fontSize={{ base: '3xl', md: '5xl' }}
            color={colors.white}
            mb={4}
          >
            Conecte-se com a <Text as="span" color={colors.secondary}>FLW</Text>
          </Heading>
          <Text fontSize={{ base: 'md', md: 'xl' }} color={colors.white} mb={6}>
            A operadora móvel que oferece liberdade, cobertura nacional e muito mais.
          </Text>
          <Button
            mt={4}
            size="lg"
            colorScheme="secondary"
            color={colors.white}
            rightIcon={<ChevronRightIcon />}
            onClick={() => navigate('/plans')}
          >
            Conheça nossos planos
          </Button>
        </Box>
      </MotionBox>

      {/* Seção Sobre Nós */}
      <Box py={16} px={4} bg={colors.white}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} alignItems="center">
          {/* Conteúdo de texto */}
          <MotionBox
            initial={{ x: -100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, ease: 'easeOut' }}
          >
            <Heading mb={6} color={colors.black}>
              Quem somos
            </Heading>
            <Text fontSize="lg" color={colors.gray[600]}>
              A FLW é uma operadora móvel virtual (MVNO) que chegou para revolucionar a forma como você se conecta. Com tecnologia de ponta e planos flexíveis, proporcionamos liberdade e qualidade para você falar e navegar sem limites.
            </Text>
            <Button
              mt={8}
              colorScheme="brand"
              onClick={() => {
                // Navegação para a página sobre nós
              }}
            >
              Saiba mais
            </Button>
          </MotionBox>

          {/* Imagem com animação */}
          <MotionImage
            initial={{ x: 100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, ease: 'easeOut' }}
          >
            <Box
              as="img"
              src={require('../../assets/chip/layout-chip-mockup.png')}
              alt="Chip FLW"
              borderRadius="md"
            />
          </MotionImage>
        </SimpleGrid>
      </Box>

      {/* Benefícios */}
      <Box py={16} px={4} bg={colors.gray[50]}>
        <Heading textAlign="center" mb={12} color={colors.black}>
          Por que escolher a FLW?
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>
          {/* Benefício 1 */}
          <MotionBox
            whileHover={{ y: -10 }}
            transition={{ duration: 0.3 }}
            textAlign="center"
            px={6}
            py={8}
            bg={colors.white}
            borderRadius="md"
            shadow="md"
          >
            <Icon boxSize={12} mb={4} color={colors.primary} as={FaMobileAlt} />
            <Text fontSize="xl" fontWeight="bold" mb={2} color={colors.black}>
              Planos sem fidelidade
            </Text>
            <Text color={colors.gray[600]}>
              Adeus contratos intermináveis! Com a FLW você tem liberdade total.
            </Text>
          </MotionBox>

          {/* Benefício 2 */}
          <MotionBox
            whileHover={{ y: -10 }}
            transition={{ duration: 0.3 }}
            textAlign="center"
            px={6}
            py={8}
            bg={colors.white}
            borderRadius="md"
            shadow="md"
          >
            <Icon boxSize={12} mb={4} color={colors.primary} as={FaWifi} />
            <Text fontSize="xl" fontWeight="bold" mb={2} color={colors.black}>
              Internet que acumula
            </Text>
            <Text color={colors.gray[600]}>
              Sobrou internet? Com a FLW sua internet acumula para o próximo mês.
            </Text>
          </MotionBox>

          {/* Benefício 3 */}
          <MotionBox
            whileHover={{ y: -10 }}
            transition={{ duration: 0.3 }}
            textAlign="center"
            px={6}
            py={8}
            bg={colors.white}
            borderRadius="md"
            shadow="md"
          >
            <Icon boxSize={12} mb={4} color={colors.primary} as={FaWhatsapp} />
            <Text fontSize="xl" fontWeight="bold" mb={2} color={colors.black}>
              WhatsApp grátis
            </Text>
            <Text color={colors.gray[600]}>
              Mensagens via WhatsApp sem custo adicional, mantendo você conectado.
            </Text>
          </MotionBox>

          {/* Benefício 4 */}
          <MotionBox
            whileHover={{ y: -10 }}
            transition={{ duration: 0.3 }}
            textAlign="center"
            px={6}
            py={8}
            bg={colors.white}
            borderRadius="md"
            shadow="md"
          >
            <Icon boxSize={12} mb={4} color={colors.primary} as={FaGlobe} />
            <Text fontSize="xl" fontWeight="bold" mb={2} color={colors.black}>
              Cobertura nacional
            </Text>
            <Text color={colors.gray[600]}>
              De norte a sul, faça chuva ou faça sol! A FLW tem cobertura em todo o Brasil.
            </Text>
          </MotionBox>
        </SimpleGrid>
      </Box>

      {/* Seção de Planos Destaque */}
      <Box py={16} px={4} bg={colors.white}>
        <Heading textAlign="center" mb={12} color={colors.black}>
          Planos que cabem no seu bolso
        </Heading>

        {loadingData ? (
          <Loading></Loading>
        ) : (
          <SimpleGrid columns={{ base: 1, sm: 2, md: 2, lg: 4 }} spacing={10}>
            {categories.map((category, index) => {
              const firstPlan = category.plans[0];
              if (!firstPlan) return null;

              const color = colorsArray[index % colorsArray.length];

              return (
                <CardPlan
                  key={category.id}
                  categoryName={category.nome}
                  plan={firstPlan}
                  color={color}
                  onSubscribe={() => navigate('/plans')}
                />
              );
            })}
          </SimpleGrid>
        )}
      </Box>

      {/* Componente de Depoimentos */}
      <Testimonials />

      {/* CTA Final */}
      <Box py={16} px={4} bg={colors.primary}>
        <Flex direction="column" alignItems="center">
          <Heading
            textAlign="center"
            mb={6}
            color={colors.white}
            fontSize={{ base: '2xl', md: '4xl' }}
          >
            Pronto para se conectar com a FLW?
          </Heading>
          <Button size="lg" colorScheme="secondary" color={colors.black} onClick={() => navigate('/plans')}>
            Assine agora
          </Button>
        </Flex>
      </Box>

      <Footer />
    </>
  );
};

export default Home;
