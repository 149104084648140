import { createGlobalStyle } from 'styled-components';
import { colors } from './colors';

export default createGlobalStyle`
    /* Estilos para a Barra de Rolagem */
    /* Navegadores WebKit (Chrome, Safari) */
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    ::-webkit-scrollbar-track {
        background: ${colors.gray[200]};
    }

    ::-webkit-scrollbar-thumb {
        background: ${colors.primary};
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: ${colors.primary};
    }

    /* Navegadores Firefox */
    scrollbar-width: thin;
    scrollbar-color: ${colors.primary} ${colors.gray[200]};

    /* Reset Básico e Estilos Globais */
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Plus Jakarta Sans', sans-serif;
        color: inherit;
    }

    html {
        font-size: 14px;
        position: relative;
        min-height: 100%;
    }

    body {
        background-color: ${colors.white};
        color: ${colors.black};
        margin: 0 !important;
        font-family: 'Plus Jakarta Sans', sans-serif;
    }

    /* Classes Utilitárias */
    .rounded-none {
        border-radius: 0px;
    }

    .rounded-r-none {
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .rounded-l-none {
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    /* Animação de Texto em Movimento */
    .moving-words-container {
        width: 100%;
        overflow: hidden;
    }
      
    .moving-word {
        display: inline-block;
        white-space: nowrap;
        animation: moveRightLeft 5s linear infinite;
    }
      
    @keyframes moveRightLeft {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(-100%);
        }
    }

    /* Estilos para Mapas Leaflet */
    .leaflet-right, .leaflet-control-zoom {
        display: none !important;
    }

    .leaflet-div-icon {
        background: none;
        border: none;
    }

    /* Animações de Transição */
    .fade-enter {
        opacity: 0;
    }
    .fade-enter-active {
        opacity: 1;
        transition: opacity 300ms;
    }
    .fade-exit {
        opacity: 1;
    }
    .fade-exit-active {
        opacity: 0;
        transition: opacity 300ms;
    }
`;