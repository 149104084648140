// src/styles/colors.jsx
export const colors = {
    // Cores da Marca
    primary: '#3E32F3',   // Cor primária da marca
    secondary: '#F4BB37', // Cor secundária ou de contraste

    // Cores Neutras
    white: '#FFFFFF',
    black: '#000000',
    gray: {
        50: '#F7FAFC',
        100: '#EDF2F7',
        200: '#E2E8F0',
        300: '#CBD5E0',
        400: '#A0AEC0',
        500: '#718096',
        600: '#4A5568',
        700: '#2D3748',
        800: '#1A202C',
        900: '#171923',
    },

    // Cores de Feedback
    red: '#E53E3E',
    green: '#38A169',
    blue: '#3182CE',
    yellow: '#ECC94B',

    // Outros
    transparent: 'transparent',
    current: 'currentColor',
};
