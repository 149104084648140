import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    HStack,
    Button,
    Input,
    FormControl,
    FormLabel,
    FormErrorMessage,
    VStack,
    Text,
    Box,
    Select,
    useToast,
    Stepper,
    Step,
    StepIndicator,
    StepStatus,
    StepIcon,
    StepNumber,
    StepSeparator,
    Flex,
    useClipboard,
} from '@chakra-ui/react';
import Api, { exceptionNotificationAPI } from '../services/api';
import { colors } from '../styles/colors';

const ModalRecharge = ({ isOpen, onClose }) => {
    const [activeStep, setActiveStep] = useState(0);

    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [lineDetails, setLineDetails] = useState(null);
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [paymentLink, setPaymentLink] = useState(null);

    const toast = useToast();
    const { onCopy, hasCopied } = useClipboard(paymentLink);

    const steps = [
        { title: 'Número', description: 'Número FLW' },
        { title: 'Plano', description: 'Selecione' },
        { title: 'Confirmação', description: 'Revisar solicitação' },
    ];

    const validatePhoneNumber = (number) => {
        const regex = /^[1-9]{2}9\d{8}$/;
        return regex.test(number);
    };

    const fetchLineDetails = async () => {
        try {
            const response = await Api.post('/linhas/detalhes', {
                numero: phoneNumber,
            });
            return response.data;
        } catch (error) {
            exceptionNotificationAPI(error);
            return null;
        }
    };

    // Função para buscar planos caso necessário
    const fetchPlans = async (categoryId) => {
        try {
            const response = await Api.get(`/planos/${categoryId}`);
            setPlans(response.data.data);
        } catch (error) {
            exceptionNotificationAPI(error);
        }
    };

    const handleNext = async () => {
        if (activeStep === 0) {
            if (!validatePhoneNumber(phoneNumber)) {
                setPhoneNumberError('Número inválido. Use o formato DDD + 9 dígitos.');
                return;
            } else {
                setPhoneNumberError('');
                setIsLoading(true);
                const response = await fetchLineDetails();
                setIsLoading(false);

                if (response && response.retorno) {
                    setLineDetails(response.detalhes);

                    if (response.detalhes.id_plano_categoria) {
                        await fetchPlans(response.detalhes.id_plano_categoria);
                    }
                    setActiveStep(activeStep + 1);
                } else {
                    toast({
                        title: 'Erro',
                        description: response.mensagem || 'Número não encontrado ou não pertence à marca.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                }
            }
        } else if (activeStep === 1) {
            if (selectedPlan) {
                setActiveStep(activeStep + 1);
            } else {
                toast({
                    title: 'Selecione um plano',
                    status: 'warning',
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleRecharge = async () => {
        setIsLoading(true);
        try {
            const payload = {
                metodo_pagamento: 'LINK',
                sms: 'S',
                numeros: [
                    {
                        numero: phoneNumber,
                        id_plano: selectedPlan.id,
                    },
                ],
            };

            const response = await Api.post('/recarga/solicitar', payload);
            setIsLoading(false);

            if (response.data.retorno && response.data.recarga && response.data.recarga.link_pagamento) {
                toast({
                    title: 'Recarga solicitada com sucesso!',
                    description: 'Verifique seu SMS para o link de pagamento.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });

                setPaymentLink(response.data.recarga.link_pagamento);
                window.open(response.data.recarga.link_pagamento, '_blank');

            } else {
                toast({
                    title: 'Erro ao solicitar recarga',
                    description: response.data.mensagem || 'Tente novamente mais tarde.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            setIsLoading(false);
            exceptionNotificationAPI(error);
        }
    };

    useEffect(() => {
        if (lineDetails) {
            if (lineDetails.id_plano) {
                fetchPlans(lineDetails.id_plano_categoria);
            } else {
                const fetchAllPlans = async () => {
                    try {
                        const categoriesResponse = await Api.get('/planos-categorias');
                        const categoriesData = categoriesResponse.data;

                        let allPlans = [];
                        for (let category of categoriesData) {
                            const plansResponse = await Api.get(`/planos/${category.id}`);
                            allPlans = allPlans.concat(plansResponse.data.data);
                        }
                        setPlans(allPlans);
                    } catch (error) {
                        exceptionNotificationAPI(error);
                    }
                };
                fetchAllPlans();
            }
        }
    }, [lineDetails]);

    useEffect(() => {
        if (lineDetails && lineDetails.id_plano && plans.length > 0) {
            const currentPlan = plans.find(
                (plan) => plan.id === parseInt(lineDetails.id_plano)
            );
            setSelectedPlan(currentPlan);
        }
    }, [plans]);

    const isPhoneNumberValid = validatePhoneNumber(phoneNumber);

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Solicitar Nova Recarga</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {paymentLink ? (
                        <VStack spacing={4}>
                            <Text fontWeight="bold" fontSize="lg">
                                Recarga solicitada com sucesso!
                            </Text>
                            <Flex
                                direction="row"
                                align="center"
                                justify="space-between"
                                border="1px solid"
                                borderColor={colors.primary}
                                borderRadius="md"
                                p={4}
                                w="full"
                            >
                                <Text fontSize="md" isTruncated>
                                    {paymentLink}
                                </Text>
                                <Button
                                    size="sm"
                                    onClick={onCopy}
                                    colorScheme="teal"
                                    variant={hasCopied ? 'solid' : 'outline'}
                                >
                                    {hasCopied ? 'Copiado!' : 'Copiar'}
                                </Button>
                            </Flex>
                            <Button
                                variant="solid"
                                colorScheme="primary"
                                backgroundColor={colors.primary}
                                color={colors.white}
                                fontWeight="400"
                                fontSize="sm"
                                onClick={onClose}
                                mt={4}
                            >
                                Fechar
                            </Button>
                        </VStack>
                    ) : (
                        <>
                            <Stepper index={activeStep} colorScheme="primary" mb={6}>
                                {steps.map((step, index) => (
                                    <Step key={index}>
                                        <StepIndicator>
                                            <StepStatus
                                                complete={<StepIcon />}
                                                incomplete={<StepNumber />}
                                                active={<StepNumber />}
                                            />
                                        </StepIndicator>
                                        <Box flexShrink='0' ml={4}>
                                            <Text fontWeight="bold">{step.title}</Text>
                                            <Text fontSize="sm" color={colors.gray[600]}>
                                                {step.description}
                                            </Text>
                                        </Box>
                                        {index < steps.length - 1 && <StepSeparator />}
                                    </Step>
                                ))}
                            </Stepper>

                            {activeStep === 0 && (
                                <VStack spacing={4}>
                                    <FormControl isInvalid={phoneNumberError}>
                                        <FormLabel>Número da Linha FLW</FormLabel>
                                        <Input
                                            placeholder="Ex: 51999999999"
                                            value={phoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                            maxLength={11}
                                        />
                                        {phoneNumberError && (
                                            <FormErrorMessage>{phoneNumberError}</FormErrorMessage>
                                        )}
                                    </FormControl>
                                </VStack>
                            )}

                            {activeStep === 1 && (
                                <Box>
                                    <Text fontSize="lg" fontWeight="bold" mb={4}>
                                        Selecione o Plano
                                    </Text>
                                    {plans.length > 0 ? (
                                        <Select
                                            placeholder="Selecione um plano"
                                            value={selectedPlan ? selectedPlan.id : ''}
                                            onChange={(e) => {
                                                const plan = plans.find(
                                                    (p) => p.id === parseInt(e.target.value)
                                                );
                                                setSelectedPlan(plan);
                                            }}
                                        >
                                            {plans.map((plan) => (
                                                <option key={plan.id} value={plan.id}>
                                                    {plan.plano} - R$ {plan.valor.toFixed(2)}
                                                </option>
                                            ))}
                                        </Select>
                                    ) : (
                                        <Text>Nenhum plano disponível.</Text>
                                    )}
                                </Box>
                            )}

                            {activeStep === 2 && (
                                <Box>
                                    <Text fontSize="lg" fontWeight="bold" mb={4}>
                                        Confirmação
                                    </Text>
                                    <Text>Número: {phoneNumber}</Text>
                                    <Text>
                                        Plano: {selectedPlan ? selectedPlan.plano : 'Nenhum plano selecionado'}
                                    </Text>
                                    <Text>Valor: R$ {selectedPlan ? selectedPlan.valor.toFixed(2) : '0.00'}</Text>
                                </Box>
                            )}

                            <Flex mt={8} justifyContent="flex-end">
                                <HStack spacing={4}>
                                    <Button
                                        onClick={activeStep === 0 ? onClose : handleBack}
                                        variant="outline"
                                        isDisabled={isLoading}
                                    >
                                        {activeStep === 0 ? 'Cancelar' : 'Voltar'}
                                    </Button>

                                    {activeStep < 2 && isPhoneNumberValid ? (
                                        <Button
                                            onClick={handleNext}
                                            variant="outline"
                                            isLoading={isLoading}
                                            isDisabled={isLoading}
                                        >
                                            Próximo
                                        </Button>
                                    ) : activeStep === 2 ? (
                                        <Button
                                            onClick={handleRecharge}
                                            variant="outline"
                                            isLoading={isLoading}
                                            isDisabled={isLoading}
                                        >
                                            Gerar Link de Pagamento
                                        </Button>
                                    ) : null}
                                </HStack>
                            </Flex>
                        </>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ModalRecharge;
