import { ChakraProvider, ColorModeScript, CSSReset } from '@chakra-ui/react';
import React from 'react';
import AppProvider from './hooks';
import RoutesList from './routes';
import GlobalStyle from './styles/global';
import theme from './styles/theme';
import useSecurityMeasures from './hooks/useSecurityMeasures';
import { RightClickModal } from './components/rightClickModal';
import WhatsAppButton from './components/whatsAppButton';
import { Loading } from './components/loading';
import { useAuth } from './hooks/auth';

const ENV = process.env.REACT_APP_ENV || 'PRD';

function App() {
    const { isOpen, onClose } = useSecurityMeasures(ENV);
    const { loading } = useAuth();

    if (loading) {
        return (
            <ChakraProvider theme={theme}>
                <GlobalStyle />
                <Loading></Loading>
            </ChakraProvider>
        );
    }

    return (
        <>
            <ColorModeScript initialColorMode={theme.config.initialColorMode} />
            <ChakraProvider theme={theme}>
                <CSSReset />
                <AppProvider>
                    <RoutesList forceRefresh={true} />
                </AppProvider>
                <WhatsAppButton />
                <GlobalStyle />
                <RightClickModal isOpen={isOpen} onClose={onClose} />
            </ChakraProvider>
        </>
    );
}

export default App;