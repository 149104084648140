import React from 'react';
import { Box, Heading, Text, SimpleGrid, Flex, Avatar } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { colors } from '../styles/colors';

// Animações com Framer Motion
const MotionBox = motion(Box);

const testimonialsData = [
    {
        name: 'João Silva',
        feedback:
            'A FLW revolucionou minha experiência móvel. Planos sem fidelidade e internet que acumula são incríveis!',
        avatar: require('../assets/avatars/joao-silva.png'),
    },
    {
        name: 'Maria Fernandes',
        feedback:
            'Cobertura nacional e WhatsApp grátis? A FLW superou todas as minhas expectativas!',
        avatar: require('../assets/avatars/maria-fernandes.png'),
    },
    // Adicione mais depoimentos conforme necessário
];

const Testimonials = () => {
    return (
        <Box py={16} px={4} bg={colors.gray[50]}>
            <Heading textAlign="center" mb={12} color={colors.black}>
                O que nossos clientes dizem
            </Heading>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                {testimonialsData.map((testimonial, index) => (
                    <MotionBox
                        key={index}
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.6, delay: index * 0.2 }}
                        bg={colors.white}
                        p={8}
                        borderRadius="md"
                        shadow="md"
                    >
                        <Flex alignItems="center" mb={4}>
                            <Avatar src={testimonial.avatar} name={testimonial.name} mr={4} />
                            <Text fontWeight="bold" color={colors.black}>
                                {testimonial.name}
                            </Text>
                        </Flex>
                        <Text color={colors.gray[600]}>{testimonial.feedback}</Text>
                    </MotionBox>
                ))}
            </SimpleGrid>
        </Box>
    );
};

export default Testimonials;