// src/components/OurValues.jsx
import React from 'react';
import { Box, Heading, SimpleGrid, Icon, Text } from '@chakra-ui/react';
import { FaLightbulb, FaHandsHelping, FaRocket, FaUsers } from 'react-icons/fa';
import { colors } from '../styles/colors';

const OurValues = () => {
    return (
        <Box py={16} px={4} bg={colors.gray[50]}>
            <Heading textAlign="center" mb={12} color={colors.black}>
                Nossos Valores
            </Heading>
            <SimpleGrid columns={{ base: 1, md: 4 }} spacing={10}>
                {/* Valor 1 */}
                <Box textAlign="center">
                    <Icon boxSize={12} mb={4} color={colors.primary} as={FaLightbulb} />
                    <Text fontSize="xl" fontWeight="bold" mb={2} color={colors.black}>
                        Inovação
                    </Text>
                    <Text color={colors.gray[600]}>
                        Buscamos constantemente soluções inovadoras para conectar pessoas de maneira mais inteligente e eficiente.
                    </Text>
                </Box>

                {/* Valor 2 */}
                <Box textAlign="center">
                    <Icon boxSize={12} mb={4} color={colors.primary} as={FaHandsHelping} />
                    <Text fontSize="xl" fontWeight="bold" mb={2} color={colors.black}>
                        Compromisso
                    </Text>
                    <Text color={colors.gray[600]}>
                        Estamos comprometidos com a satisfação dos nossos clientes, oferecendo serviços de qualidade e atendimento personalizado.
                    </Text>
                </Box>

                {/* Valor 3 */}
                <Box textAlign="center">
                    <Icon boxSize={12} mb={4} color={colors.primary} as={FaRocket} />
                    <Text fontSize="xl" fontWeight="bold" mb={2} color={colors.black}>
                        Crescimento
                    </Text>
                    <Text color={colors.gray[600]}>
                        Acreditamos no crescimento conjunto, impulsionando nossos clientes e parceiros rumo ao sucesso.
                    </Text>
                </Box>

                {/* Valor 4 */}
                <Box textAlign="center">
                    <Icon boxSize={12} mb={4} color={colors.primary} as={FaUsers} />
                    <Text fontSize="xl" fontWeight="bold" mb={2} color={colors.black}>
                        Conexão
                    </Text>
                    <Text color={colors.gray[600]}>
                        Valorizamos as conexões humanas, fortalecendo laços e aproximando pessoas.
                    </Text>
                </Box>
            </SimpleGrid>
        </Box>
    );
};

export default OurValues;
