import axios from 'axios';

const Api = axios.create({
    baseURL: 'https://api.conteltelecom.com.br',
    //baseURL: 'https://api-hml.conteltelecom.com.br'
});

Api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('@FlwApp:token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

export const exceptionNotificationAPI = (error, toast) => {
    if (/500/.test(error.message)) {
        console.error('Failed to connect Web Service (500).');
    } else if (/404/.test(error.message)) {
        console.error('Route Not Found (404).');
    } else if (/401/.test(error.message)) {
        sessionStorage.clear();
        localStorage.removeItem('@FlwApp:token');
        window.location.href = "/";
    } else if (/400/.test(error.message)) {
        let notifications = error.response.data.errors;
        if (notifications && notifications.length > 0) {
            notifications.forEach((not) => {
                console.log(not);
            })
        }
    } else {
        console.error(error.message);
    }
}

export default Api;