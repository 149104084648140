// src/pages/FAQ/index.jsx
import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    InputGroup,
    InputLeftElement,
    Input,
    Heading,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Icon,
    Text,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { FaQuestionCircle } from 'react-icons/fa';
import Header from '../../components/header';
import { Footer } from '../../components/footer';
import { colors } from '../../styles/colors';
import { Loading } from '../../components/loading';
import Api, { exceptionNotificationAPI } from '../../services/api';

const FAQ = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [faqData, setFaqData] = useState([]);
    const [loading, setLoading] = useState(true);

    // Usando um ref para rastrear se os dados já foram buscados
    const dataFetchedRef = useRef(false);

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;

        const fetchFaqData = async () => {
            try {
                // Obtém as categorias
                const categoriesResponse = await Api.get('/faq/categorias');
                const categories = categoriesResponse.data;

                // Para cada categoria, obtém os itens
                const categoriesWithItems = await Promise.all(
                    categories.map(async (category) => {
                        const itemsResponse = await Api.get(`/faq/categoria/${category.id}`);
                        const items = itemsResponse.data;
                        return {
                            id: category.id,
                            category: category.descricao,
                            items: items.map((item) => ({
                                id: item.id,
                                question: item.pergunta,
                                answer: item.resposta,
                            })),
                        };
                    })
                );

                setFaqData(categoriesWithItems);
                setLoading(false);
            } catch (error) {
                exceptionNotificationAPI(error);
                setLoading(false);
            }
        };

        fetchFaqData();
    }, []);

    // Função de filtro para a busca
    const filteredFaq = faqData
        .map((category) => ({
            ...category,
            items: category.items.filter(
                (item) =>
                    item.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    item.answer.toLowerCase().includes(searchQuery.toLowerCase())
            ),
        }))
        .filter((category) => category.items.length > 0);

    return (
        <>
            <Header />

            {/* Seção Hero */}
            <Box
                bg={colors.primary}
                py={16}
                px={4}
                color={colors.white}
                textAlign="center"
            >
                <Heading mb={4}>Central de Ajuda</Heading>
                <Text fontSize="lg">
                    Encontre respostas para as perguntas mais frequentes
                </Text>
            </Box>

            {/* Barra de Busca */}
            <Box maxW="800px" mx="auto" mt={-10} px={4}>
                <InputGroup mb={10}>
                    <InputLeftElement pointerEvents="none">
                        <SearchIcon color={colors.gray[500]} />
                    </InputLeftElement>
                    <Input
                        type="text"
                        placeholder="Pesquise por palavras-chave..."
                        bg={colors.white}
                        boxShadow="md"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </InputGroup>
            </Box>

            {/* Accordion de FAQ */}
            <Box maxW="800px" mx="auto" px={4} mb={16}>
                {loading ? (
                    <Loading></Loading>
                ) : filteredFaq.length > 0 ? (
                    <Accordion allowMultiple>
                        {filteredFaq.map((category) => (
                            <AccordionItem key={category.id} border="none" mb={6}>
                                <h2>
                                    <AccordionButton
                                        _expanded={{ bg: colors.primary, color: colors.white }}
                                        bg={colors.gray[100]}
                                        borderRadius="md"
                                        boxShadow="md"
                                    >
                                        <Box flex="1" textAlign="left" fontWeight="bold">
                                            <Icon as={FaQuestionCircle} mr={2} />
                                            {category.category}
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <Accordion allowToggle>
                                        {category.items.map((item) => (
                                            <AccordionItem key={item.id} border="none">
                                                <h3>
                                                    <AccordionButton
                                                        _expanded={{ bg: colors.secondary, color: colors.black }}
                                                        bg={colors.gray[50]}
                                                        mt={2}
                                                        borderRadius="md"
                                                    >
                                                        <Box flex="1" textAlign="left">
                                                            {item.question}
                                                        </Box>
                                                        <AccordionIcon />
                                                    </AccordionButton>
                                                </h3>
                                                <AccordionPanel
                                                    pb={4}
                                                    bg={colors.gray[50]}
                                                    mt={2}
                                                    borderRadius="md"
                                                >
                                                    <Text color={colors.gray[700]}>{item.answer}</Text>
                                                </AccordionPanel>
                                            </AccordionItem>
                                        ))}
                                    </Accordion>
                                </AccordionPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>
                ) : (
                    <Text textAlign="center" color={colors.gray[600]}>
                        Nenhum resultado encontrado para "{searchQuery}".
                    </Text>
                )}
            </Box>

            <Footer />
        </>
    );
};

export default FAQ;
