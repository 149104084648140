import React from 'react';
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Text,
    Button,
    VStack
} from '@chakra-ui/react';
import { colors } from '../styles/colors';

export const PrivacyPolicy = ({ isOpen, onClose }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl" scrollBehavior="inside">
            <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
            <ModalContent backgroundColor={colors.background} p={6}>
                <ModalCloseButton color={colors.black} />
                <Text fontSize="xl" color={colors.black} mb={4}>
                    Política de Privacidade
                </Text>
                <ModalBody maxHeight="70vh" overflowY="auto">
                    <Text fontSize="md" color={colors.black} mb={4}>
                        A sua privacidade é importante para nós. É política do FLW respeitar a sua privacidade em relação a qualquer informação sua que possamos coletar no site FLW, e outros sites que possuímos e operamos.
                    </Text>
                    <Text fontSize="md" color={colors.black} mb={4}>
                        Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos coletando e como será usado.
                    </Text>
                    <Text fontSize="md" color={colors.black} mb={4}>
                        Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados.
                    </Text>
                    <Text fontSize="md" color={colors.black} mb={4}>
                        Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei.
                    </Text>
                    <Text fontSize="md" color={colors.black} mb={4}>
                        O nosso site pode ter links para sites externos que não são operados por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas desses sites e não podemos aceitar responsabilidade por suas respectivas políticas de privacidade.
                    </Text>
                    <Text fontSize="md" color={colors.black} mb={4}>
                        Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados.
                    </Text>
                    <Text fontSize="md" color={colors.black} mb={4}>
                        O uso continuado de nosso site será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais, entre em contato conosco.
                    </Text>
                    <Text fontSize="md" color={colors.black} mb={4}>
                        O serviço Google AdSense que usamos para veicular publicidade usa um cookie DoubleClick para veicular anúncios mais relevantes em toda a Web e limitar o número de vezes que um determinado anúncio é exibido para você. Para mais informações sobre o Google AdSense, consulte as FAQs oficiais sobre privacidade do Google AdSense.
                    </Text>
                    <Text fontSize="md" color={colors.black} mb={4}>
                        Utilizamos anúncios para compensar os custos de funcionamento deste site e fornecer financiamento para futuros desenvolvimentos. Os cookies de publicidade comportamental usados por este site foram projetados para garantir que você forneça os anúncios mais relevantes sempre que possível, rastreando anonimamente seus interesses e apresentando coisas semelhantes que possam ser do seu interesse.
                    </Text>
                    <Text fontSize="md" color={colors.black} mb={4}>
                        Vários parceiros anunciam em nosso nome e os cookies de rastreamento de afiliados simplesmente nos permitem ver se nossos clientes acessaram o site através de um dos sites de nossos parceiros, para que possamos creditá-los adequadamente e, quando aplicável, permitir que nossos parceiros afiliados ofereçam qualquer promoção que pode fornecê-lo para fazer uma compra.
                    </Text>
                    <Text fontSize="md" color={colors.black} mb={4}>
                        <strong>Compromisso do Usuário</strong><br />
                        O usuário se compromete a fazer uso adequado dos conteúdos e da informação que o FLW oferece no site e com caráter enunciativo, mas não limitativo:
                    </Text>
                    <Text fontSize="md" color={colors.black} mb={4}>
                        A) Não se envolver em atividades que sejam ilegais ou contrárias à boa fé a à ordem pública;
                    </Text>
                    <Text fontSize="md" color={colors.black} mb={4}>
                        B) Não difundir propaganda ou conteúdo de natureza racista, xenofóbica, kiwibet ou azar, qualquer tipo de pornografia ilegal, de apologia ao terrorismo ou contra os direitos humanos;
                    </Text>
                    <Text fontSize="md" color={colors.black} mb={4}>
                        C) Não causar danos aos sistemas físicos (hardwares) e lógicos (softwares) do FLW, de seus fornecedores ou terceiros, para introduzir ou disseminar vírus informáticos ou quaisquer outros sistemas de hardware ou software que sejam capazes de causar danos anteriormente mencionados.
                    </Text>
                    <Text fontSize="md" color={colors.black} mb={4}>
                        <strong>Mais informações</strong><br />
                        Esperemos que esteja esclarecido e, como mencionado anteriormente, se houver algo que você não tem certeza se precisa ou não, geralmente é mais seguro deixar os cookies ativados, caso interaja com um dos recursos que você usa em nosso site.
                    </Text>
                    <Text fontSize="md" color={colors.black} mb={4}>
                        Esta política é efetiva a partir de <strong>20 de agosto de 2024, 17:32</strong>.
                    </Text>

                </ModalBody>
                <ModalFooter>
                    <VStack spacing={4} w="full">
                        <Button
                            variant="solid"
                            colorScheme="primary"
                            backgroundColor={colors.primary}
                            color={colors.black}
                            fontWeight="400"
                            fontSize="sm"
                            onClick={onClose}
                        >
                            Fechar
                        </Button>
                    </VStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
