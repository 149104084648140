import {
    Box,
    VStack,
    Button,
    Container,
    Flex,
    Grid,
    Image,
    Link,
    Stack,
    Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { PrivacyPolicy } from '../components/privacyPolicy';
import { TermsOfUse } from '../components/termsOfUse';
import { colors } from '../styles/colors';

export const Footer = ({ ...rest }) => {
    const [termsOfUse, setTermsOfUse] = useState(false);
    const [privacyPolicy, setPrivacyPolicy] = useState(false);
    const currentYear = new Date().getFullYear();

    return (
        <>
            <TermsOfUse isOpen={termsOfUse} onClose={() => setTermsOfUse(false)} />
            <PrivacyPolicy
                isOpen={privacyPolicy}
                onClose={() => setPrivacyPolicy(false)}
            />
            <Container maxW="container.xl" marginTop="20">
                <Grid
                    as="footer"
                    templateColumns={{
                        base: '1fr',
                        md: 'repeat(3, 1fr)',
                    }}
                    gap={6}
                    padding="1rem"
                    role="contentinfo"
                >
                    {/* Box 1: Logo e Imagens */}
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        gridColumn={{ base: '1 / -1', md: '1 / 2' }}
                    >
                        <VStack spacing={1} alignItems="center">
                            <Image
                                cursor={'pointer'}
                                src={require('../assets/icons/logo-black.png')}
                                w="9rem"
                            />
                            <Text
                                fontSize="0.6rem"
                                align="center"
                                color={colors.gray[500]}
                                mt={8}
                            >
                                Desenvolvido por
                            </Text>
                            <Image
                                src={require('../assets/bahtech/logo-bahtech-black.png')}
                                w="8rem"
                                mt={2}
                                opacity={0.6}
                            />
                        </VStack>
                    </Box>

                    {/* Box 2: Instale o App */}
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        gridColumn={{ base: '1 / -1', md: '2 / 3' }}
                    >
                        <Text fontSize="lg" color={colors.black}>
                            Instale o App
                        </Text>
                        <Stack direction="column" spacing={4} mt={4}>
                            <Link
                                href="https://play.google.com/store/apps/details?id=..."
                                isExternal
                            >
                                <Image
                                    src={require('../assets/badges/google-play-badge.png')}
                                    alt="Google Play"
                                    w="150px"
                                    h="45px"
                                />
                            </Link>
                            <Link
                                href="https://apps.apple.com/app/id..."
                                isExternal
                            >
                                <Image
                                    src={require('../assets/badges/app-store-badge.png')}
                                    alt="App Store"
                                    w="150px"
                                    h="45px"
                                />
                            </Link>
                        </Stack>
                    </Box>

                    {/* Box 3: Atendimento */}
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems={{ base: 'center', md: 'flex-end' }}
                        textAlign={{ base: 'center', md: 'right' }}
                        gridColumn={{ base: '1 / -1', md: '3 / 4' }}
                    >
                        <Text fontSize="lg" color={colors.black}>
                            Atendimento
                        </Text>
                        <Text fontSize="sm" color={colors.gray[500]}>
                            Suporte Técnico e Ajuda
                        </Text>
                        <Stack direction="column" mt={6} spacing={4}>
                            <Link
                                href="https://wa.me/558008002101"
                                isExternal
                                fontSize="sm"
                                color={colors.primary}
                                _hover={{ textDecoration: 'underline', color: colors.secondary }}
                            >
                                Contato
                            </Link>
                        </Stack>
                    </Box>
                </Grid>

                {/* Second Grid for Footer Bottom */}
                <Grid
                    as="footer"
                    borderTopWidth={1}
                    borderColor={colors.gray[200]}
                    mt={7}
                    templateColumns={{
                        base: '1fr',
                        md: 'repeat(3, 1fr)',
                    }}
                    w="full"
                    gap={6}
                    paddingY={'30px'}
                    role="contentinfo"
                >
                    {/* Footer Bottom Left */}
                    <Flex
                        gridColumn={{ base: '1 / -1', md: '1 / 2' }}
                        justifyContent={{ base: 'center', md: 'flex-start' }}
                    >
                        <Text fontSize="sm" color={colors.gray[500]}>
                            ©{currentYear} Todos os direitos reservados
                        </Text>
                    </Flex>

                    {/* Footer Bottom Right */}
                    <Flex
                        gridColumn={{ base: '1 / -1', md: '3 / 4' }}
                        flexDirection={{ base: 'row', md: 'column', lg: 'row' }}
                        justifyContent={{ base: 'center', md: 'flex-end' }}
                        alignItems={{ base: 'center', md: 'flex-end' }}
                        gap={2}
                    >
                        <Button
                            onClick={() => setTermsOfUse(true)}
                            fontSize="sm"
                            color={colors.primary}
                            variant="link"
                            fontWeight="normal"
                            _hover={{ textDecoration: 'underline', color: colors.secondary }}
                            width="full"
                            justifyContent={{ base: 'center', md: 'flex-end' }}
                        >
                            <Text textAlign={{ base: 'center', md: 'right' }}>Termos de Uso</Text>
                        </Button>
                        <Button
                            onClick={() => setPrivacyPolicy(true)}
                            fontSize="sm"
                            color={colors.primary}
                            variant="link"
                            fontWeight="normal"
                            _hover={{ textDecoration: 'underline', color: colors.secondary }}
                            width="full"
                            justifyContent={{ base: 'center', md: 'flex-end' }}
                        >
                            <Text textAlign={{ base: 'center', md: 'left' }}>Política de Privacidade</Text>
                        </Button>
                    </Flex>
                </Grid>
            </Container>
        </>
    );
};
