import React from 'react';
import { Link, Tooltip, IconButton } from '@chakra-ui/react';
import { FaWhatsapp } from 'react-icons/fa';
import { colors } from '../styles/colors';

const WhatsAppButton = () => {
    return (
        <Tooltip label="Acesso ao suporte 24hs" aria-label="Tooltip WhatsApp">
            <Link
                href="https://wa.me/558008002101"
                isExternal
                position="fixed"
                bottom="20px"
                right="20px"
                zIndex="1000"
            >
                <IconButton
                    icon={<FaWhatsapp />}
                    colorScheme="whatsapp"
                    aria-label="WhatsApp Support"
                    size="lg"
                    isRound
                    _hover={{ transform: 'scale(1.1)' }}
                />
            </Link>
        </Tooltip>
    );
};

export default WhatsAppButton;