// src/pages/Plans/index.jsx
import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    Heading,
    SimpleGrid,
    Flex,
    useMediaQuery,
} from '@chakra-ui/react';
import { motion, useInView } from 'framer-motion';
import Header from '../../components/header';
import { Footer } from '../../components/footer';
import CardPlanPlan from '../../components/cardPlanPlan';
import Api, { exceptionNotificationAPI } from '../../services/api';
import { colors } from '../../styles/colors';
import { Loading } from '../../components/loading';

const MotionBox = motion(Box);
const MotionHeading = motion(Heading);

const Plans = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const dataFetchedRef = useRef(false);

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;

        const fetchData = async () => {
            try {
                const categoriesResponse = await Api.get('/planos-categorias');
                const categoriesData = categoriesResponse.data;

                const categoriesWithPlans = await Promise.all(
                    categoriesData.map(async (category) => {
                        const plansResponse = await Api.get(`/planos/${category.id}`);
                        const plansData = plansResponse.data.data;
                        return {
                            ...category,
                            plans: plansData,
                        };
                    })
                );

                setCategories(categoriesWithPlans);
                setLoading(false);
            } catch (error) {
                exceptionNotificationAPI(error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <Header />

            <Box pb={16}>
                {loading ? (
                    <Loading />
                ) : (
                    categories.map((category, index) => (
                        <CategorySection
                            key={category.id}
                            category={category}
                            index={index}
                            totalCategories={categories.length}
                        />
                    ))
                )}
            </Box>

            <Footer />
        </>
    );
};

const CategorySection = ({ category, index, totalCategories }) => {
    const ref = useRef(null);
    const inView = useInView(ref, { once: true });

    const [isMobile] = useMediaQuery('(max-width: 767px)');
    const [isTablet] = useMediaQuery('(min-width: 768px) and (max-width: 991px)');

    // Determinar direção da animação
    const isFirstOrLast = index === 0 || index === totalCategories - 1;
    const animationDirection = isFirstOrLast ? -100 : 100;

    // Animação do título da categoria
    const animationProps = {
        initial: { x: animationDirection, opacity: 0 },
        animate: inView ? { x: 0, opacity: 1 } : {},
        transition: { duration: 0.8, ease: 'easeOut' },
    };

    // Alternar cores para cada seção
    const sectionBgColor = index % 2 === 0 ? colors.gray[50] : colors.white;

    return (
        <Box ref={ref} mb={16} bg={sectionBgColor} py={8} borderRadius="md">
            {/* Título da categoria */}
            <MotionHeading
                {...animationProps}
                textAlign="center"
                mb={8}
                fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}
                color={colors.primary}
            >
                {category.nome}
            </MotionHeading>

            {/* Cards dos planos */}
            {isMobile ? (
                // Layout para mobile: cards em coluna
                <SimpleGrid columns={1} spacing={6} px={4}>
                    {category.plans.map((plan) => (
                        <CardPlanPlan
                            key={plan.id}
                            plan={plan}
                            categoryName={category.nome} // Passando o categoryName
                        />
                    ))}
                </SimpleGrid>
            ) : isTablet ? (
                // Layout para tablet: cards em linha com rolagem horizontal
                <MotionBox
                    overflowX="auto"
                    css={{
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                    }}
                    px={4}
                >
                    <Flex
                        as={motion.div}
                        initial={{ x: 50, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 0.8, ease: 'easeOut' }}
                        style={{ gap: '16px' }}
                    >
                        {category.plans.map((plan) => (
                            <CardPlanPlan
                                key={plan.id}
                                plan={plan}
                                categoryName={category.nome} // Passando o categoryName
                                minW="250px"
                            />
                        ))}
                    </Flex>
                </MotionBox>
            ) : (
                // Layout para desktop: cards centralizados
                <Flex
                    justify="center"
                    align="center"
                    gap={6}
                    px={4}
                >
                    {category.plans.map((plan) => (
                        <CardPlanPlan
                            key={plan.id}
                            plan={plan}
                            categoryName={category.nome} // Passando o categoryName
                        />
                    ))}
                </Flex>
            )}
        </Box>
    );
};

export default Plans;
